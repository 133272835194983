@import "../../styles/base/variables";
@import "../../styles/base/colors";

.mobileFeatureGrid {
    display: none;
    @media screen and (max-width: 830px) {
        display: block;
    }
}

.losun {
    animation: card 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    grid-column: span 3;
    grid-row: span 3;
}

.container {
    display: grid;
    grid-template-columns: repeat(8, 80px);
    grid-template-rows: 60px 60px repeat(3, 80px);
    gap: 20px;
    margin-top: 50px;
    max-width: 1040px;

    @media screen and (max-width: 830px) {
        display: none;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.02);
        border-radius: 15px;
        background: white;
        overflow: hidden;
    }

    img {
        width: 100%;
    }

    .losun {
        animation: card 2s;
        animation-fill-mode: forwards;
        opacity: 0;
        grid-column: span 3;
        grid-row: span 3;
    }

    .starfsanaegja {
        animation: card 2s;
        animation-delay: 0.25s;
        animation-fill-mode: forwards;
        opacity: 0;

        grid-column-start: span 2;
        grid-row: span 2;
    }

    .launahlutfall {
        animation: card 2s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        opacity: 0;

        grid-column-start: span 3;
        grid-row: span 3;
        height: 230px;
    }

    .fyrirtaekjabilar {
        animation: card 2s;
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: 0.75s;
        grid-column-start: span 3;
        grid-row: span 2;
        height: 210px;
    }

    .kynjahlutfall {
        animation: card 2s;
        animation-fill-mode: forwards;
        opacity: 0;
        animation-delay: 1s;
        grid-column: 4;
        grid-column-end: 6;
        grid-row-start: 3;
        grid-row-end: 6;
        height: 230px;
    }
    .stefnur {
        animation: card 2s;
        animation-fill-mode: forwards;
        animation-delay: 1.25s;
        opacity: 0;

        grid-column-start: span 3;
        grid-row: span 2;
        margin-top: -10px;
        height: 170px;
    }

    @keyframes card {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}
