@import "../../styles/base/variables";
@import "../../styles/base/colors";
.container {
    max-width: 100vw;
}
.card {
    padding: 30px 40px;
    max-width: 450px;
    border-radius: 15px;

    h3,
    h4,
    li {
        color: inherit;
    }
    h3 {
        font-size: 27px;
    }
    h4 {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 18px;
    }

    ul {
        list-style-position: inside;
        padding-left: 0;
        margin: 10px 0px;
    }
    li {
        font-size: 18px;
    }

    a,
    button {
        margin-top: 10px;
        max-width: 150px;
    }
}
