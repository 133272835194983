@import "../../styles/base/variables";
@import "../../styles/base/colors";

.statsContainer {
    max-width: 650px;
    @media (max-width: 500px) {
        flex-direction: column;
        gap: 40px;
    }
}
