@import "../../styles/base/variables";
@import "../../styles/base/colors";
.container {
    display: flex;
    background: #f0f0f0;
    padding: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;

    .review {
        font-size: 22px;
        margin-bottom: 40px;
    }
    .reviewerName {
        font-weight: 600;
        padding: 0px;
        margin: 0px;
    }
    .reviewerTitle {
        margin: 0px;
    }

    p {
        text-align: center;
        color: #4433cd;
    }
}
