@import "../../../styles/base/colors.scss";
@import "../../../styles/base/variables.scss";

.introductionVideo {
    width: 95%;
    height: 95%;
    aspect-ratio: 16/9;
    max-width: 1024px;
    max-height: 900px;
    margin-top: 15vh;
    margin-bottom: 15vh;
    z-index: 10;
}

.container {
    z-index: 2;
    background-color: #f6f6f6;
    overflow: hidden;
    position: relative;

    .leaf1 {
        padding: 0px;
        animation: leaf1 3s;
        animation-fill-mode: forwards;
        opacity: 0;
        color: #fd974f;
        position: absolute;
        top: min(-25vw, -264px);
        left: min(-35vw, -370px);
        height: max(52vw, 549px);
        width: max(60vw, 633px);
        z-index: 1;
    }

    .leaf2 {
        padding: 0px;
        animation: leaf2 3s;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
        opacity: 0;

        color: #4433cd;
        position: absolute;
        top: 150px;
        right: -45%;
        height: 52vw;
        width: 60vw;
        z-index: 1;
    }

    .leaf3 {
        padding: 0px;
        animation: leaf3 3s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        opacity: 0;
        color: #4fc878;
        position: absolute;
        top: max(13vw, 280px);
        left: min(-28vw, -400px);
        height: max(52vw, 600px);
        width: max(40vw, 517px);
        z-index: 1;
        // background-color: red;
    }

    @keyframes leaf1 {
        0% {
            opacity: 0;
            transform: translateY(0px) rotate(47deg);
        }
        100% {
            opacity: 1;
            transform: translateY(-20px) rotate(47deg);
        }
    }
    @keyframes leaf2 {
        0% {
            opacity: 0;
            transform: translateY(0px) rotate(260deg);
        }
        100% {
            opacity: 1;
            transform: translateY(-20px) rotate(260deg);
        }
    }
    @keyframes leaf3 {
        0% {
            opacity: 0;
            transform: translateY(0px) rotate(30deg);
        }
        100% {
            opacity: 1;
            transform: translateY(-20px) rotate(30deg);
        }
    }

    @keyframes card {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

.tryFreeButton {
    font-weight: 600;
    align-self: center;
    white-space: nowrap;
    padding: 7px 20px;
    opacity: 0;
    animation: card 2s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    font-weight: 400;
}
.mainText {
    z-index: 2;
    font-size: calc(2rem + 0.6vw);
    line-height: calc(2.7rem + 0.6vw);
    font-weight: 600;
    color: $primary-color;
    margin: 0 15px;
    margin-bottom: 30px;
    text-align: center;
    max-width: calc(30rem + 5vw);
    animation: card 2s;
    animation-fill-mode: forwards;
}

.subText {
    z-index: 2;
    font-size: calc(1rem + 0.5vw);
    line-height: calc(1.5rem + 0.6vw);
    font-weight: 400;
    color: #5d5d5d;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    max-width: calc(30rem + 5vw);
    animation: card 2s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    opacity: 0;
    margin: 0 15px;
}
